/*-------------------------------------------------------------------------------------------------------------------------------*/
/* RESET STYLES */
/*-------------------------------------------------------------------------------------------------------------------------------*/
html {
  overflow-y: scroll;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

body * {
  -webkit-text-size-adjust: none;
}

.clear {
  clear: both;
  overflow: hidden;
  height: 0px;
  font-size: 0px;
  display: block;
}

ul,
ol {
  list-style: none;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* GLOBAL SETTINGS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*main*/
body * {
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a,
a:link,
a:visited,
a:active,
a:hover {
  outline: none;
}

#content-block {
  position: relative;
  overflow: hidden;
  left: 0;
  min-height: 100vh;
}

.row-entry {
  padding: 55px 0;
  background: #fafafa;
  border-bottom: 1px #e7eaef solid;
  position: relative;
}

.row-entry:last-child {
  border: none;
}

.row-entry.bright {
  background: #fff;
}

.row-entry.shade {
  background: #f3f3f3;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  position: relative;
}

.spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  left: -10000px;
  transition: opacity .25s, left 0s .25s;
  opacity: 0;
}

.spinner#loader-wrapper {
  position: fixed;
  z-index: 12;
}

.wrapper .spinner {
  border: 1px #E1E1E1 solid;
}

.spinner.active {
  left: 0;
  opacity: 1;
  transition: opacity .15s, left 0s 0s;
}

.spinner.disabled {
  opacity: 0;
  left: -10000px;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #007D91;
  animation: spinner .6s linear infinite;
  -webkit-animation: spinner .6s linear infinite;
}

/**/
@keyframes loaderanim {
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}
.loading-inline{display: inline-block; white-space: nowrap; width: 0; vertical-align: baseline; margin-left: .18em;}
.loading-inline:before, .loading-inline:after, .loading-inline span:before{content: "."; opacity: 0; animation: loaderanim 1s ease infinite;}
.loading-inline:before{animation-delay: .1s;}
.loading-inline span:before{animation-delay: .2s;}
.loading-inline:after{animation-delay: .3s;}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .row-entry {
    padding: 45px 0;
  }

  .row-entry.small {
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .row-entry {
    padding: 45px 0;
  }

  .row-entry.small {
    padding: 20px 0;
  }
}

/*body selection*/
body ::selection {
  color: #fff;
  background: #007D91;
}

body ::-moz-selection {
  color: #fff;
  background: #007D91;
}

/*helper classes*/
.line-through {
  display: inline-block;
  position: relative;
  padding: 0 5px;
}

.line-through:after {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
  background: currentColor;
  content: "";
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.floatfix {
  float: left;
  width: 100%;
}

.bg-entry {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: #4A5660;
}

/* #content-block.phone .bg-entry{background-attachment: scroll;} */
.elevated {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.elevated-lg {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

/*typography*/
body {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  color: #4e4e4e;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .25px;
  -webkit-font-smoothing: antialiased;
  background: #fafafa;
}

.text {
  font-size: 16px;
  line-height: 24px;
}

.text.sm,
.sl {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: .7px;
}

.text.xs {
  font-size: 12px;
  line-height: 14px;
}

.white {
  color: #fff;
}

b,
strong {
  font-weight: 700;
  color: #212121;
  letter-spacing: .5px;
}

.white b,
.white strong {
  color: #fff;
}

i {
  font-style: italic;
}

a {
  color: #007D91;
  text-decoration: underline;
  cursor: pointer;
}

a.secondary{
    text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a.secondary:hover{
    text-decoration: underline;
}

.white a:not([class*="button"]) {
  color: #5BD1E7;
}

h1,
.h1 {
  font-weight: 300;
  font-size: 42px;
  line-height: 48px;
  color: #4e4e4e;
  letter-spacing: 0.46px;
}

.white h1,
.white .h1 {
  color: #fff;
}

h2,
.h2 {
  font-weight: 300;
  font-size: 27px;
  line-height: 35px;
  color: #4e4e4e;
}

.white h2,
.white .h2 {
  color: #fff;
}

h3,
.h3 {
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: #4e4e4e;
  letter-spacing: .26px;
}

.white h3,
.white .h3 {
  color: #fff;
}

h4,
.h4 {
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  color: #4e4e4e;
}

.white h4,
.white .h4 {
  color: #fff;
}

h5,
.h5 {
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #4e4e4e;
}

.white h5,
.white .h5 {
  color: #fff;
}

h6,
.h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
}

.white h6,
.white .h6 {
  color: #fff;
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .login-title h1 {
    font-size: 24px;
    line-height: 32px;
  }
}

/**/
.button-back {
  background: url(/assets/img/icon-4.png) left bottom repeat-x;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.button-back a {
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  padding-left: 35px;
  position: relative;
}

.button-back a:before {
  width: 14px;
  height: 14px;
  border-left: 2px currentColor solid;
  border-top: 2px currentColor solid;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -7px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/*buttons*/
.btn {
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid;
  transition: .25s;
  text-decoration: none;
}

.btn:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
}

.btn-primary {
  color: #fff;
  background: #007D91;
  border-color: #007D91;
}

.btn-primary:focus {
  background: #005f6e;
  border-color: #005f6e;
}

.btn-primary.btn-optional {
  color: #007D91;
  background: transparent;
}

.btn-primary.btn-optional:focus {
  background: #e0e0e0;
}

.btn-secondary {
  color: #fff;
  background: #4A5660;
  border-color: #4A5660;
}

.btn-secondary:focus {
  background: #343435;
  border-color: #343435;
}

.btn-secondary.btn-optional {
  color: #4A5660;
  background: transparent;
}

.btn-secondary.btn-optional:focus {
  background: #e0e0e0;
}

.btn-reverse {
  color: #007D91;
  background: #fff;
  border-color: #fff;
}

.btn-reverse:focus {
  background: #e0e0e0;
}

.btn-reverse.btn-optional {
  color: #fff;
  background: transparent;
}

.btn-reverse.btn-optional:focus {
  background: #e0e0e0;
  color: #007d91;
}

.btn-green {
  color: #fff;
  background: #1B831D;
  border-color: #1B831D;
}

.btn-green:focus {
  background: #146316;
  border-color: #146316;
}

.btn-green.btn-optional {
  color: #1B831D;
  background: transparent;
}

.btn-green.btn-optional:focus {
  background: #e0e0e0;
}

.btn-disabled,
button[disabled] {
  color: #6D6E71 !important;
  background: #C9C9C9 !important;
  border-color: #C9C9C9 !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}

.btn-xlg {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  padding: 20px 30px;
  border-radius: 32px;
  max-width: 100%;
  letter-spacing: 0.7px;
}

.btn-lg {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  padding: 13px 25px;
  border-radius: 30px;
  max-width: 100%;
  letter-spacing: 0.7px;
}

.btn-md {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  padding: 7px 15px;
  border-radius: 18px;
  max-width: 100%;
  text-transform: none;
  letter-spacing: 0.7px;
}

.btn-sm {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 5px 10px;
  border-radius: 15px;
  max-width: 100%;
  text-transform: none;
  letter-spacing: 0.7px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-wrapper {
  font-size: 0;
  margin: 0 -5px -10px -5px;
}

.btn-wrapper .btn {
  margin: 0 5px 10px 5px;
}

/**/
.btn-action {
  width: 50px;
  height: 50px;
  background: center center no-repeat;
  display: inline-block;
  vertical-align: top;
  border: 2px solid rgba(193, 202, 212, .5);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: .25s;
  cursor: pointer;
  position: relative;
}

.btn-action:hover {
  border-color: #007D91;
}

.btn-action img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  max-width: 100%;
  height: auto;
}

.btn-action-wrapper {
  margin: -5px;
  font-size: 0;
  white-space: nowrap;
}

.btn-action-wrapper .btn-action {
  margin: 5px;
}
.btn-action-wrapper.inline {font-size: 0;}
.btn-action-wrapper.inline .btn-action, .btn-action-wrapper.inline h1 {
    display: inline-block; vertical-align: middle;
}
.btn-action-wrapper.inline .btn-action {margin-left: 15px;}

/**/
.btn-close {
  width: 42px;
  height: 42px;
  display: inline-block;
  vertical-align: top;
  border: 2px currentColor solid;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: .3s;
  -webkit-transition: .3s;
  color: #c1cad4;
}

.white .btn-close {
  color: #fff;
}

.btn-close:hover {
  color: #007D91;
}

.white .btn-close:hover {
  color: rgba(255, 255, 255, .8);
}

.btn-close:before,
.btn-close:after {
  width: 22px;
  height: 2px;
  background: currentColor;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 50%;
  margin-top: -1px;
  content: "";
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.btn-close:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/**/
.btn-simple {
  text-decoration: none;
}

.btn-simple:hover {
  text-decoration: underline;
}

button.btn-simple {
  background: none;
  border: 0 none;
  margin: 0;
  color: #007D91;
  text-decoration: none;
}

button.btn-simple:hover {
  text-decoration: underline;
}

button[disabled] {
  color: #4e4e4e;
  opacity: 0.6;
}

button[disabled].btn-simple:hover {
  text-decoration: none;
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .btn-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .btn-wrapper .btn,
  .btn {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .btn-xlg {
    font-size: 13px;
    line-height: 18px;
    padding: 13px 25px;
    border-radius: 30px;
  }
}

/*inputs*/
.input::-webkit-input-placeholder {
  color: #4E4E4E;
  opacity: 1;
}

.input:-moz-placeholder {
  color: #4E4E4E;
  opacity: 1;
}

.input::-moz-placeholder {
  color: #4E4E4E;
  opacity: 1;
}

.input:-ms-input-placeholder {
  color: #4E4E4E;
  opacity: 1;
}

.white .input::-webkit-input-placeholder {
  color: #D9D9D9;
}

.white .input:-moz-placeholder {
  color: #D9D9D9;
}

.white .input::-moz-placeholder {
  color: #D9D9D9;
}

.white .input:-ms-input-placeholder {
  color: #D9D9D9;
}

.sl {
  margin-bottom: 5px;
  display: block;
}

input,
textarea,
select {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  border-radius: 0;
  background: none;
  border: none;
  margin: 0;
  width: 100%;
}

textarea {
  display: block;
  resize: none;
  overflow: auto;
}

select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-indent: .01px;
  text-overflow: "";
}

input[type="submit"] {
  width: auto;
  min-width: 0;
}

/*input simple*/
.input {
  position: relative;
  height: 56px;
  line-height: 52px;
  border: 2px #C6CFD7 solid;
  color: #4e4e4e;
  font-size: 16px;
  padding: 0 20px;
  border-radius: 4px;
  transition: border-color .3s;
  -webkit-transition: border-color .3s;
  letter-spacing: .5px;
}

.input.disabled{
    pointer-events: none;
    opacity: .32;
}

.input.small {
  height: 34px;
  line-height: 32px;
  border-width: 1px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
}

select.input {
  padding-right: 50px;
  cursor: pointer;
}

select.input option {
  font-size: 16px;
  line-height: 24px;
  color: #4E4E4E;
}

select.input option[disabled] {
  color: #9E9E9E;
}

select.input optgroup {
  color: #007D91;
  font-weight: 500;
}

.white select.input {
  background-image: url(/assets/img/icon-1-light.png);
}

select.input.rounded {
  border-radius: 28px;
  -webkit-border-radius: 28px;
  color: #4e4e4e;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
}

select.input.small {
  background-position: right 0px center;
  padding: 0 20px 0 0;
  text-align: left;
  border: none;
  font-size: 14px;
  font-weight: 700;
  color: #4a4a4a;
  height: 32px;
  padding-top: 2px;
}

.input-placeholder {
  height: 56px;
  line-height: 52px;
  border: 2px transparent solid;
  color: #4e4e4e;
  font-size: 16px;
  padding: 0 20px;
  position: absolute;
  left: 0;
  top: 0;
}

.typed .input-placeholder {
  display: none;
}

textarea.input {
  height: 135px;
  line-height: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.input-wrapper.collapse:not(.focus):not(.typed) textarea.input {
  height: 56px;
}

.input-wrapper {
  position: relative;
  z-index: 0;
}

.input-icon{position: absolute; left: 100%; margin-left: -28px; top: 50%; width: 40px; height: auto; transform: translateX(-50%) translateY(-50%);}
.input-icon+input{padding-right: 56px; text-overflow: ellipsis;}

.input-content{
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 4px;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
    padding: 5px 18px;
    z-index: 2;
    max-height: 250px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.input-content a{
    display: block;
    color: #757575;
    line-height: 16px!important;
    text-decoration: none;
    padding: 5px 0;
}

.input-content a:hover, .input-content a:hover b{
    color: #007D91;
}

.white .input {
  border-color: #C6CFD7;
  color: #D9D9D9;
}

.white select.input {
  border-color: #C6CFD7;
  color: #D9D9D9;
}

.white .input option {
  color: initial !important;
}

.input.icon {
  padding-right: 52px;
  background-position: right 15px center;
  background-repeat: no-repeat;
}

.input-wrapper.typed .input {
  border-color: #9E9E9E;
}

.input-wrapper.focus .input {
  border-color: #007D91;
}

.white .input-wrapper.typed .input {
  border-color: #fff;
}

.white .input-wrapper.focus .input {
  border-color: #fff;
}

.message-alert-wrapper {
  min-height: 50px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.message-alert {
  position: relative;
  background: #D0011B;
  padding: 0 15px 0 47px;
  margin-bottom: 5px;
}

.message-alert img {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -9px;
}

.message-alert .align {
  display: table-cell;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  height: 40px;
  vertical-align: middle;
  width: 10000px;
}

.submit-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100%;
  opacity: 0;
}

/*input material*/
.input-wrapper-material {
  position: relative;
}

.input-wrapper-material label {
  font-size: 16px;
  line-height: 18px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0;
  top: 0;
  transition: .3s;
  -webkit-transition: .3s;
  letter-spacing: .5px;
  font-weight: 300;
}

.input-wrapper-material.focus label,
.input-wrapper-material.typed label {
  font-size: 12px;
  top: -18px;
}

.input-material {
  height: 34px;
  line-height: 32px;
  text-overflow: ellipsis;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  border-bottom: 2px #e0e4e9 solid;
  position: relative;
  font-size: 16px;
  color: #C6CFD7;
  letter-spacing: .5px;
  color: rgba(0, 0, 0, 0.54);
}

.input-wrapper-material:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background: #3aa995;
  content: "";
  transition: .3s;
  -webkit-transition: .3s;
}

.input-wrapper-material.typed:after {
  background: #C1CAD4;
  width: 100%;
}

.input-wrapper-material.focus:after {
  background: #007D91;
  width: 100%;
}

.input-wrapper-material.focus .input-material {
  color: #007D91;
}

.ng-submitted .input-wrapper-material.has-error label {
  color: red;
}

.ng-submitted .input-wrapper-material.has-error:after {
  background: red;
}

/**/
.input-wrapper .icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: -1;
}

.input-wrapper .small+.icon-wrapper {
  width: 20px;
}

.input-wrapper .icon-wrapper img {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}

.input-wrapper .icon-wrapper .icon-angle {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  margin-left: -5px;
}

/**/
.has-error input {
  color: red !important;
  border-color: red!important;
}

.has-error .input {
  color: red !important;
  border-color: red!important;
}

.has-error.input-wrapper-material:after {
  background: red;
}

.has-error.input-wrapper-material label {
  color: red;
}

[formControlName].ng-touched.ng-invalid{color: red; border-color: red;}

/*checkbox*/
.checkbox {
  display: inline-block;
  vertical-align: top;
  min-width: 18px;
  min-height: 24px;
  position: relative;
}

.checkbox input {
  position: absolute;
  left: -10000px;
  top: 0;
}

.checkbox span {
  position: relative;
  display: block;
  padding-left: 18px;
  cursor: pointer;
  transition: color .15s;
  -webkit-transition: color .15s;
  font-size: 16px;
  line-height: 24px;
  text-indent: 8px;
}

.checkbox:hover span {
  color: #9B9B9B;
}

.checkbox span:before {
  width: 18px;
  height: 18px;
  border: 2px #c9cac0 solid;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  margin-top: 2px;
}

.checkbox.radio span:before {
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.checkbox span:after {
  position: absolute;
  left: 4px;
  top: 5px;
  margin-top: 2px;
  width: 10px;
  height: 10px;
  background: #007D91;
  content: "";
  transition: .15s;
  -webkit-transition: .15s;
  opacity: 0;
  transform: scale(.9);
  -webkit-transform: scale(.9);
}

.checkbox.radio span:after {
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.checkbox input:checked+span:after, .checkbox.active span:after {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}

.checkbox.style1 span:before {
  border-color: #D0021B;
}

.checkbox.style1 span:after {
  background: #D0021B;
}

.checkbox.style2 span:before {
  border-color: #F57F1F;
}

.checkbox.style2 span:after {
  background: #F57F1F;
}

.checkbox.style3 span:before {
  border-color: #F8E71C;
}

.checkbox.style3 span:after {
  background: #F8E71C;
}

.styled-checkbox {
  width: 35px;
  height: 15px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.styled-checkbox input {
  display: none;
}

.styled-checkbox span {
  display: block;
  width: 100%;
  height: 100%;
  background: #9b9b9b;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  cursor: pointer;
  transition: .15s;
  -webkit-transition: .15s;
}

.styled-checkbox span:before {
  height: 21px;
  width: 21px;
  background-color: #EFEfef;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: -3px;
  content: "";
  border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: inherit;
  -webkit-transition: inherit;
}

.styled-checkbox input:checked+span {
  background: #6AD9EC;
}

.styled-checkbox input:checked+span:before {
  left: 14px;
  background: #06818E;
}

.styled-checkbox.highlighted input:checked+span {
  background: #ffcf81;
}

.styled-checkbox.highlighted input:checked+span:before {
  background: #f57f1f;
}

.styled-checkbox.active span {
  background: #6AD9EC;
}

.styled-checkbox.active span:before {
  left: 14px;
  background: #06818E;
}

.styled-checkbox.highlighted.active span {
  background: #ffcf81;
}

.styled-checkbox.highlighted.active span:before {
  background: #f57f1f;
}

[disabled] {
  cursor: not-allowed !important;
}

/*table*/
.table {
  border: 1px #e1e1e1 solid;
  table-layout: fixed;
  width: 100%;
}

.table.not-fix {
  table-layout: auto;
}

.table-responsive {
  position: relative;
}

.table-responsive .table {
  min-width: 1140px;
}

.table th {
  background: #42444a;
  color: #fff;
  padding: 0 15px;
  vertical-align: middle;
  height: 59px;
}

.table td {
  padding: 12px 15px;
  vertical-align: middle;
}

.table.small td{
    padding: 5px 15px;
}

.table-panel.align-right+.table td:last-child,
.table-panel.align-right+.table th:last-child {
  padding-right: 65px;
}

.table tr {
  background: #fff;
}

.table tr:nth-child(2n) {
  background: #f4f4f4;
}

.table tr.highlight{
    background: #343435;
    color: rgba(255,255,255,0.88);
    padding: 0;
    border: none;
}

.table tr.highlight b{
    color: inherit;
}

.table tr.disabled {
    color: #BCBCBC;
    cursor: not-allowed;
}

.sort {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.sort:hover {
  text-decoration: underline;
}

.sort:after,
.sort:before {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
  content: "";
  border-bottom: 5px #fff solid;
  border-left: 4.5px transparent solid;
  border-right: 4.5px transparent solid;
  opacity: .5;
}

.sort:before {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  top: auto;
  bottom: 50%;
  margin: 0 0 -7px 0;
}

.asc .sort:before {
  opacity: 1;
}

.desc .sort:after {
  opacity: 1;
}

.table th .checkbox span:before {
  border-color: #9b9b9b;
}

/**/
.table-no-search {
  border: 1px solid #E1E1E1;
  background: #fff;
}

.table-no-search.transparent {
  background: transparent;
  border-color: rgba(255, 255, 255, .15);
  margin-top: -1px;
}

.table-no-search .align {
  height: 360px;
  display: table-cell;
  vertical-align: middle;
  width: 10000px;
  text-align: center;
  padding: 30px;
}

/**/
.table .checkbox-wrapper {
  padding-right: 0;
  width: 60px;
}

/**/
.table-panel {
  background: #42444a;
  padding: 18px 10px 18px 23px;
  border-bottom: 1px #55575c solid;
  position: relative;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .table-panel.align-right {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    padding: 16px;
  }

  .table-panel.align-right>.row>*[class*="col"]:not(:last-child) {
    display: none;
  }
}

.table-panel .row,
.table-panel [class*="col-"] {
  position: static;
}

.table-panel-icon {
  width: 32px;
  height: 28px;
  display: inline-block;
  vertical-align: top;
}

.table-panel-icon:not(:first-child) {
  margin-left: 5px;
}

.table-panel-icon>.icon {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.table-panel-icon>.icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}

.table-panel-icon .toggle {
  position: absolute;
  text-align: left;
  z-index: 4;
}

.table-panel-icon .toggle-layer {
  position: fixed;
  left: -10000px;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  transition: opacity .25s, left 0s .25s;
  visibility: hidden;
}

.table-panel-icon .toggle.active+.toggle-layer {
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, left 0s;
}

.table-panel-icon .toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 460px;
  opacity: 0;
  visibility: hidden;
  right: -10000px;
  transition: opacity .15s, right 0s .15s;
  -webkit-transition: opacity .15s, right 0s .15s;
}

.table-panel-icon .toggle.active {
  opacity: 1;
  visibility: visible;
  right: 0;
  transition: opacity .15s, right 0s;
  -webkit-transition: opacity .15s, right 0s;
}

.table-panel-icon .toggle .btn-close {
  border: none;
  position: absolute;
  right: 5px;
  top: 8px;
}

/**/
.filtration-support-sort-entry {
  cursor: pointer;
  transition: .25s;
  padding-left: 25px;
  position: relative;
}

.filtration-support-sort-entry:before {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 4px;
  background: url(/assets/img/icon-9.png) center center no-repeat;
  opacity: 0;
}

.filtration-support-sort-entry.sort:before {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

.filtration-support-sort-entry:not(:last-child) {
  margin-bottom: 18px;
}

.filtration-support-sort-entry.active,
.filtration-support-sort-entry:hover {
  color: #068FA0;
}

.filtration-support-sort-entry.active:before {
  opacity: 1;
}

.filtration-support-sort-entry.active.desc:before {
  transform: rotate(180deg)
}

.filtration-support-toggle-wrapper {
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
}

.filtration-support-toggle-title {
  background-color: #F4F4F4;
  padding: 18px 15px;
  font-weight: 700;
  border-bottom: 1px rgba(0, 0, 0, .1) solid;
}

.filtration-support-toggle-title:last-child {
  border: none;
}

.filtration-support-toggle-entry {
  padding: 20px 32px;
  border-bottom: 1px rgba(0, 0, 0, .1) solid;
}

.filtration-support-toggle-wrapper:not(.active) {
  display: none;
}

.def-link {
  color: #007D91;
}

.empty-response td {
  background: #f8fafc;
  height: 300px;
  text-align: center;
}

/* Tablets (>=768px)*/
@media (max-width: 991px) {

  .table,
  .table tr,
  .table td,
  .table thead,
  .table tbody {
    display: block;
    width: auto !important;
  }

  .table thead {
    display: none;
  }

  .table tr {
    padding: 10px;
    position: relative;
  }

  .table td {
    padding: 5px !important;
  }

  .table td[data-name]:before {
    content: attr(data-name)":";
    font-weight: 700;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    color: #000;
  }

  .table-panel-length .align {
    margin-top: 0;
    top: 20px;
  }

  .table-panel h4 {
    font-size: 17px;
    line-height: 22px;
  }

  .table {
    border: none;
    font-size: 14px;
    line-break: 19px;
  }

  .table tr {
    padding: 0;
    border: 1px #e1e1e1 solid;
    border-radius: 4px;
    padding-bottom: 15px;
  }

  .table tr {
    margin-bottom: 10px;
  }

  .table td {
    padding: 0 80px 0 30px !important;
    text-align: left;
  }

  .table td.right-top-r {
    position: absolute;
    right: 20px;
    top: 12px;
    padding: 0 !important;
  }

  .table td.right-bottom-r {
    position: absolute;
    right: 20px;
    bottom: 12px;
    padding: 0 !important;
  }

  .table td.title {
    padding: 12px 80px 12px 30px !important;
    border-bottom: 1px solid #E1E1E1;
  }

  .table tr.highlight td.title{
      padding: 12px 18px!important;
  }

  .table td.title[data-name]:before {
    font-size: 1.2em;
  }

  .table td.title+td {
    padding-top: 15px !important;
  }

  /**/
  .table-panel-icon .toggle {
    max-width: 100%;
  }

  .table-panel-icon .toggle+.toggle-layer {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .filtration-support-toggle-entry {
    padding: 20px;
  }

  .empty-response{padding: 0!important;}
  .empty-response td{height: auto; text-align: center; padding: 25px!important;}
}

/*pagination*/
.pagination {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
}

.pagination li {
  float: left;
}

.pagination li a {
  display: block;
  min-width: 34px;
  line-height: 32px;
  padding: 0 5px;
  background: #fff;
  border: 1px #e1e1e1 solid;
  font-size: 16px;
  text-decoration: none;
  transition: .15s;
  -webkit-transition: .15s;
  color: #4E4E4E;
}

.pagination li:not(:first-child) a {
  border-left: none;
}

.pagination .pagination-first a,
.pagination .pagination-prev a,
.pagination .pagination-next a,
.pagination .pagination-last a {
  line-height: 30px;
}

.pagination li:first-child a {
  border-radius: 4px 0 0 4px;
}

.pagination li:last-child a {
  border-radius: 0 4px 4px 0;
}

.pagination li:hover a {
  background: #f4f4f4;
}

.pagination li:active a {
  background: #e4e4e4;
}

.pagination li.active a {
  background: #007D91;
  color: #fff;
  border-top-color: #007D91;
  border-bottom-color: #007D91;
}

.white .pagination li a {
  border-color: rgba(198, 207, 215, 0.5);
  background: transparent;
  color: #FFF;
}

.white .pagination li:hover a {
  background: rgba(255, 255, 255, .05);
}

.white .pagination li:active a {
  background: rgba(255, 255, 255, .15);
}

.white .pagination li.active a {
  background: #007D91;
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .pagination li a {
    font-size: 14px;
    min-width: 32px;
  }
}

/*angle icon*/
.icon-angle {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content: "";
  transition: .25s;
  border-left: 2px currentColor solid;
  border-bottom: 2px currentColor solid;
  position: relative;
  top: -.11em;
}

.icon-angle.left {
  transform: rotate(-315deg);
  -webkit-transform: rotate(-315deg);
}

.icon-angle.right {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* PANELS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/**/
.card-popup {
  max-width: 365px;
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  background: #fff;
}

.popup-wrapper .card-popup {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.card-popup-align {
  height: 245px;
  padding: 50px;
  display: table-cell;
  vertical-align: middle;
  width: 400px;
}

/**/
.card-title {
  box-shadow: 0 2px 8px 0 rgba(74, 74, 74, 0.15);
  padding: 20px 25px;
  border-left: 3px #007D91 solid;
  background: #fff;
}

.card-content {
  border: 1px #e1e1e1 solid;
}

/**/
.panel{
    padding: 34px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 8px 0 rgba(74,74,74,0.15);
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* POPUPS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.popup-wrapper {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  transition-delay: .3s;
  -webkit-transition-delay: .3s;
}

.popup-wrapper.active {
  left: 0;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
}

.popup-wrapper .bg-layer {
  position: absolute;
  left: 0;
  top: -100px;
  right: 0;
  bottom: -100px;
  background: rgba(0, 0, 0, .8);
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out;
  opacity: 0;
}

.popup-wrapper .bg-layer.white {
  background: rgba(255, 255, 255, .8);
}

.popup-wrapper.active .bg-layer {
  opacity: 1;
}

.popup-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  text-align: center;
  transition: transform .3s ease-out, opacity .3s ease-out, left 0s .3s;
  -webkit-transition: transform .3s ease-out, opacity .3s ease-out, left 0s .3s;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  opacity: 0;
  left: -10000px;
  padding: 15px;
}

.popup-wrapper.active .popup-content {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
  left: 0;
  transition: transform .3s ease-out, opacity .3s ease-out, left 0s 0s;
  -webkit-transition: transform .3s ease-out, opacity .3s ease-out, left 0s 0s;
  position: relative;
}

.popup-content:after {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  content: "";
}

.popup-container {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
  text-align: left;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(74, 74, 74, 0.25);
  border-radius: 8px;
  margin-left: -1px;
}

.popup-container.size-1 {
  max-width: 650px;
}

.popup-container.size-1 .popup-align {
  padding: 60px 75px 60px 75px;
}

.popup-container.size-2 {
  max-width: 530px;
}

.popup-container.size-3 {
  max-width: 1140px;
}

.popup-container.size-3 .popup-align{
    padding: 45px 95px;
}

.popup-container.size-4 {
  max-width: 100%;
}

.popup-container.size-2 .popup-align {
  padding: 55px 50px 40px 50px;
}

.popup-content .layer-close {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 10000px;
}

.popup-line {
  height: 1px;
  background: url(/assets/img/icon-4.png);
}

.popup-container .button-close {
  position: absolute;
  top: 25px;
  right: 25px;
}

.popup-bg {
  margin: 0 -75px;
  background-color: #F4F4F4;
  padding: 0 75px;
}

.popup-info {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: currentColor;
  text-align: center;
  font-weight: 300;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.popup-info .align {
  height: 40px;
  display: table-cell;
  width: 10000px;
  vertical-align: middle;
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .popup-container.size-1 .popup-align {
    padding: 50px 15px 35px 15px;
  }

  .popup-container.size-3 .popup-align {
    padding: 25px 15px 25px 15px;
  }

  .popup-container .button-close {
    top: 10px;
    right: 10px;
  }

  .popup-bg {
    margin: 0 -15px;
    padding: 0 15px;
  }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* HEADER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.content-align {
  padding-top: 138px;
}

.content-align.small {
  padding-top: 90px;
}

header a,
.languages .link {
  transition: color .3s;
  -webkit-transition: color .3s;
  text-decoration: none;
}

.header-top {
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
}

.header-top .container {
  height: 90px;
}

#logo {
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -12px;
  width: 180px;
}

#logo img {
  display: block;
  width: 100%;
}

.logo-svg-wrapper {
  display: inline-block;
  width: 195px;
}

.logo-svg-wrapper img {
  width: 100%;
  display: block;
}

nav {
  position: absolute;
  left: 170px;
  font-size: 0;
}

nav a {
  display: inline-block;
  vertical-align: top;
  color: #9B9B9B;
  font-size: 14px;
  font-weight: 500;
  line-height: 90px;
  letter-spacing: .5px;
  margin: 0 30px;
  position: relative;
}

nav a:after {
  position: absolute;
  width: 0px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  bottom: 0;
  height: 3px;
  background: #4A5660;
  content: "";
  transition: width .3s;
  -webkit-transition: width .3s;
}

nav a.active,
nav a:hover {
  color: #4A5660;
}

nav a.active:after {
  width: 40px;
}

.header-right {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -33px;
  font-size: 0;
}

.languages {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.languages span {
  display: inline-block;
  vertical-align: top;
  padding: 0 3px;
}

.languages span:last-child {
  display: none;
}

.languages .link {
  color: inherit;
  text-decoration: none;
  letter-spacing: .5px;
  padding: 0;
}

.languages .link.active,
.languages .link:hover {
  color: #007D91;
}

header .languages {
  position: absolute;
  right: 105px;
  top: 50%;
  margin-top: -8px;
}

.header-right .user {
  display: inline-block;
  vertical-align: middle;
  padding: 15px 0;
  font-size: 14px;
  letter-spacing: .5px;
}

.header-right .user .icon {
  padding-right: 20px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  vertical-align: top;
}

.header-right .user .icon .icon-angle {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -9px;
}

.header-right .user .icon.active .icon-angle {
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
  margin-top: -2px;
}

.header-right .user .icon img {
  display: block;
  width: 38px;
  height: 38px;
}

.header-right .user .toggle {
  position: absolute;
  top: 100%;
  right: 0;
  background: #343435;
  padding: 25px;
  width: 290px;
  transform-origin: 50% 0;
  -webkit-transform-origin: 50% 0;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transition: .3s;
  -webkit-transition: .3s;
  z-index: 1;
}

.header-right .user .toggle.active {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}

.header-right .user .toggle a {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 9px 0;
  color: #fff;
  display: block;
}

.header-right .user .toggle a:hover {
  color: #BCBEC0;
}

.header-right .user .toggle a.active {
  color: #6AD9EC;
}

.header-right-title {
  font-size: 14px;
  padding-right: 55px;
}

.header-right-username {
  font-size: 16px;
  font-weight: 500;
}

/**/
.header-bottom {
  height: 48px;
  background: #4A5660;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.header-bottom>.container,
.header-bottom>.container,
.header-bottom-title,
.header-bottom-title .align {
  height: inherit;
}

.header-bottom-title {
  font-size: 14px;
  line-height: 14px;
  padding-right: 22px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-align: left;
  font-weight: 500;
}

.header-bottom-title .icon-angle {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -9px;
}

.header-bottom-title.active .icon-angle {
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
  margin-top: -2px;
}

.header-bottom-title .align {
  display: table-cell;
  vertical-align: middle;
}

/**/
.header-slide-wrapper {
  position: relative;
  z-index: -2;
}

.header-slide {
  position: fixed;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: #4A5660;
  transform-origin: 50% 0;
  -webkit-transform-origin: 50% 0;
  transform: translateY(138px);
  -webkit-transform: translateY(138px);
  transition: .5s;
  -webkit-transition: .5s;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  z-index: -1;
}

.header-slide>.align {
  position: absolute;
  left: 0;
  top: 138px;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 52px 0 15px 0;
}

.header-slide>.align {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.header-slide>.align::-webkit-scrollbar {
  display: none;
}

.header-slide.active {
  top: 0;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.header-slide-line-background {
  height: 2px;
  background: url(/assets/img/icon-2.png);
}

.account-selector {
  font-weight: 300;
}

.account-selector-title {
  border-bottom: 1px solid #C6CFD7;
  padding: 4px 10px;
  margin-bottom: 20px;
}

.account-selector-title a {
  color: #FFF !important;
}

.account-selector-entry {
  transition: .25s;
  border-bottom: 1px rgba(198, 207, 215, 0.5) solid;
  padding: 12px 24px;
  cursor: pointer;
}

.account-selector-entry:hover,
.account-selector-entry.active {
  background: #007D91;
  color: #FFF;
}

/**/
#hamburger {
  width: 50px;
  height: 51px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -25px;
  cursor: pointer;
  display: none;
  transition: .3s;
  -webkit-transition: .3s;
}

#hamburger.active {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

#hamburger span {
  position: absolute;
  left: 12px;
  right: 12px;
  height: 3px;
  background: #007D91;
  transition: .3s;
  -webkit-transition: .3s;
}

#hamburger span:nth-child(1) {
  top: 16px;
}

#hamburger.active span:nth-child(1) {
  opacity: 0;
  top: 24px;
}

#hamburger span:nth-child(2) {
  top: 24px;
}

#hamburger.active span:nth-child(2) {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#hamburger span:nth-child(3) {
  top: 24px;
}

#hamburger.active span:nth-child(3) {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#hamburger span:nth-child(4) {
  bottom: 16px;
}

#hamburger.active span:nth-child(4) {
  opacity: 0;
  bottom: 24px;
}

.error-not-found {
  min-height: 300px;
  text-align: center;
  display: flex;
  align-items: center;
}

.error-not-found .align {
  width: 100%;
}

/**/
.user-info {
  background: #F4F4F4;
  border-left: 4px #007D91 solid;
  border-bottom: 1px #E5E5E5 solid;
  padding: 25px 25px 25px 21px;
}

/**/
#help{position: absolute; right: 180px; top: 50%; margin-top: -10px;}
#help img{display: block;}

/* Tablets (>=768px)*/
@media (max-width: 991px) {
    #help{right: 132px;}
  .content-align {
    padding-top: 110px;
  }

  .content-align.small {
    padding-top: 70px;
  }

  .languages {
    font-size: 14px;
  }

  header .languages {
    right: 65px;
  }

  .header-slide {
    transform: translateY(110px);
    -webkit-transform: translateY(110px);
  }

  #hamburger {
    display: block;
  }

  .header-top .container {
    height: 70px;
  }

  .header-bottom {
    height: 40px;
  }

  .nav-overflow-close-layer {
    position: fixed;
    left: -10000px;
    top: -100px;
    width: 100%;
    bottom: -100px;
    z-index: -1;
    background: rgba(50, 50, 50, .5);
    cursor: pointer;
    opacity: 0;
    transition: opacity .5s, left 0s .5s;
    -webkit-transition: opacity .5s, left 0s .5s;
  }

  .nav-overflow-close-layer.active {
    opacity: 1;
    left: 0;
    transition: opacity .5s, left 0s;
    -webkit-transition: opacity .5s, left 0s;
  }

  .nav-overflow {
    position: fixed;
    top: -100px;
    bottom: -100px;
    right: 0;
    width: 320px;
    background: #fff;
    z-index: -1;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18), 0 16px 16px 0 rgba(0, 0, 0, 0.24);
    transition: .5s ease;
    -webkit-transition: .5s ease;
    transform: translateX(104%);
    -webkit-transform: translateX(104%);
    transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
    -webkit-transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
  }

  .nav-overflow.active {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }

  .nav-content {
    position: absolute;
    left: 0;
    top: 170px;
    bottom: 100px;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  nav {
    position: relative;
    left: auto;
    top: auto;
    border-top: 1px #eee solid;
  }

  .header-right {
    position: relative;
    right: auto;
    top: auto;
    margin: 0;
  }

  .header-right .user {
    display: block;
    padding: 0;
  }

  .header-right .user .icon {
    display: none;
  }

  .header-right .user .toggle {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    -webkit-transform: none;
    width: auto;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  nav a:after {
    display: none;
  }

  nav a,
  .header-right .user .toggle a {
    color: #4E4E4E;
    line-height: 20px;
    padding: 25px;
    margin: 0;
    display: block;
  }

  .header-right .user .toggle a {
    border-top: 2px #eee solid;
  }

  nav a:hover,
  .header-right .user .toggle a:hover,
  nav a.active,
  .header-right .user .toggle a.active {
    color: #007D91;
  }
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .header-slide-entry {
    padding: 20px 10px;
  }

  .header-slide-select {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 35px;
    margin-bottom: 0;
  }

  .header-slide>.align {
    top: 110px;
  }

  .header-bottom-title {
    font-size: 12px;
    line-height: 12px;
  }

  .header-bottom-title span {
    display: none;
  }

  .header-bottom-title .align {
    width: 10000px;
  }

  .header-bottom-title .align b {
    display: block;
  }

  .logo-svg-wrapper {
    width: 120px;
    height: 53px;
  }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* FOOTER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/**/
footer {
  color: #CFD0D1;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

footer a {
  color: inherit;
  text-decoration: none;
  transition: color .3s;
  -webkit-transition: color .3s;
}

footer a:hover {
  color: #007D91;
}

.footer-top {
  background: #343435;
  padding: 40px 0 20px 0;
}

.footer-title {
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: .25px;
  border-bottom: 2px #525253 solid;
  padding-bottom: 7px;
  margin-bottom: 7px;
  text-transform: uppercase;
}

/**/
.footer-bottom {
  background: #212121;
  padding: 22px 0;
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  footer {
    font-size: 16px;
    line-height: 24px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: .7px;
  }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* LOGIN */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.fullscreen-wrapper {
  position: relative;
}

.fullscreen-align {
  display: table-cell;
  vertical-align: top;
  height: 100vh;
  width: 10000px;
  padding: 80px 0;
}

.login-title {
  max-width: 580px;
  margin: 0 auto;
}

.card-login {
  max-width: 435px;
  border-radius: 4px;
  background: #fff;
  padding: 50px 65px 50px 65px;
  margin: 0 auto;
}

.card-login-line {
  height: 2px;
  background: url(/assets/img/icon-3.png);
  margin-right: -80px;
  margin: 25px 0 35px 0;
}

.login-languages {
  position: absolute;
  right: 0;
  top: 40px;
  width: 100%;
  text-align: right;
  z-index: 2;
}

.login-languages .languages {
  color: rgba(255, 255, 255, .5);
}

.login-languages .languages .link.active,
.login-languages .languages .link:hover {
  color: #fff;
}

body.fr form[name="forgotPasswordForm"] input.button-lg {
  font-size: 11px;
}

.password-info {
  font-size: 14px;
  position: relative;
  padding-left: 25px;
}

.password-info:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: 2px #E0E4E9 solid;
  border-radius: 12px;
}

.password-info:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 8px;
  width: 10px;
  height: 5px;
  border-left: 2px #FFF solid;
  border-bottom: 2px #FFF solid;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  display: none;
}

.password-info.invalid {
  color: red;
}

.password-info.active {
  color: #1B831D;
}

.password-info.active:before {
  border-color: #1B831D;
  background: #1B831D;
}

.password-info.active:after {
  display: block;
}

/* Tablets (>=768px)*/
@media (max-width: 991px) {}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .fullscreen-align {
    padding: 40px 0 15px 0;
  }

  .card-login {
    padding: 40px 30px;
  }

  .login-languages {
    top: 10px;
  }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* INVENTORY */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.status-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: currentColor;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  position: relative;
  top: -.08em;
}

.status-text {
  display: inline-block;
  vertical-align: middle;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* PROFILE */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.profile-wrapper {
  padding: 55px 75px 40px 75px;
  max-width: 555px;
  margin: 0 auto;
}

/*Phones (<768px)*/
@media (max-width: 767px) {
  .profile-wrapper {
    padding: 35px 15px;
  }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* MEDIA QUERIES */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*Laptops*/
@media (min-width: 992px) and (max-width: 1370px) and (max-height: 700px) {}

/*Desktops (>=1200px)*/
@media (min-width: 1200px) {}

/*Desktops (>=992px)*/
@media (max-width: 1199px) {}

/* Tablets (>=768px)*/
@media (max-width: 991px) {}

/*Phones (<768px)*/
@media (max-width: 767px) {}

/*Laptops*/
@media (min-width: 992px) and (max-width: 1370px) and (max-height: 700px) {
  .header-slide>.align {
    padding-top: 15px;
  }

  .header-slide-select {
    padding-bottom: 14px;
    margin-bottom: 0;
  }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* COLORS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*primary*/
.c-primary {
  color: #007D91 !important;
}

.c-primary-bg {
  background: #007D91;
}

.c-primary-shade {
  color: #06818E !important;
}

.c-primary-shade-bg {
  background: #06818E;
}

.c-primary-tint {
  color: #6AD9EC !important;
}

.c-primary-tint-bg {
  background: #6AD9EC;
}

/*secondary*/
.c-secondary {
  color: #4A5660 !important;
}

.c-secondary-bg {
  background: #4A5660;
}

.c-secondary-shade {
  color: #343435 !important;
}

.c-secondary-shade-bg {
  background: #343435;
}

.c-secondary-tint {
  color: #C6CFD7 !important;
}

.c-secondary-tint-bg {
  background: #C6CFD7;
}

/*neutral*/
.c-neutral {
  color: #5f5f5f !important;
}

.c-neutral-bg {
  background: #5f5f5f;
}

.c-neutral-shade {
  color: #212121 !important;
}

.c-neutral-shade-bg {
  background: #212121;
}

.c-neutral-tint {
  color: #9e9e9e !important;
}

.c-neutral-tint-bg {
  background: #9e9e9e;
}

/*statuses*/
.c-success {
  color: #1B831D !important;
}

.c-success-bg {
  background: #1B831D;
}

.c-warning {
  color: #F57F1F !important;
}

.c-warning-bg {
  background: #F57F1F;
}

.c-error {
  color: #D0021B !important;
}

.c-error-bg {
  background: #D0021B;
}

.dropdown {
  position: absolute;
  width: 170px;
  background: #343435;
  left: 100%;
  top: 15px;
  z-index: 2;
  text-align: left;
  padding: 8px 14px;
  margin-left: -15px;
  transform-origin: 50% 0;
  -webkit-transform-origin: 50% 0;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transition: .3s;
  -webkit-transition: .3s;
}

.dropdown span {
  font-size: 12px;
  left: 24px;
  font-weight: 400;
}

.dropdown.active {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}

.dropdown a {
  color: #fff;
  font-size: 14px;
  display: block;
  text-decoration: none;
  font-weight: 500;
  line-height: 24px;
  transition: all 0.35s ease-in;
  -webkit-transition: all 0.35s ease-in;
  white-space: normal;
}

.dropdown a:hover {
  color: #6EDAED;
}

.rel {
  position: relative;
}

.action-img {
  position: absolute;
  height: 19px;
  width: 5px;
  left: 50%;
  top: 50%;
  margin-top: -9.5px;
  margin-left: -2.5px;
}

.action-img i {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #007D91;
}

.action-img i:before,
.action-img i:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #007D91;
}

.action-img i {
  top: 0px;
  left: 0px;
}

.action-img i:before {
  top: 15px;
  left: 0px;
}

.action-img i:after {
  top: 8px;
  left: 0px;
}

.disable .btn-action {
  cursor: default;
}

.disable .action-img i:before,
.disable .action-img i:after,
.disable .action-img i {
  background: rgba(193, 202, 212, .5);
}

.disable .btn-action:hover {
  border-color: rgba(193, 202, 212, .5);
}

.button-title {
  font-size: 16px;
  color: rgba(74, 86, 98, 0.87);
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.button-inline {
  display: inline-block;
  vertical-align: middle;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* DIDs */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.tabs-wrapper{background: #F9F9F9; text-transform: uppercase;}
.tabs-wrapper .align{height: 57px; display: table-cell; vertical-align: middle; width: 10000px; border-bottom: 5px transparent solid; cursor: pointer; transition: .25s;}
.tabs-wrapper .align:hover{border-bottom-color: #C6CFD7;}
.tabs-wrapper .align.active{border-bottom-color: #007D91;}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* TERMS POPUP */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.sa > *{margin-bottom: 15px;}
.sa ul li{position: relative; padding: 4px 0 4px 18px;}
.sa ul li:before{content: ""; width: 4px; height: 4px; border-radius: 50%; background: #007D91; position: absolute; left: 5px; top: 14px;}
.popup-scroll{max-height: 60vh; overflow: auto; -webkit-overflow-scrolling: touch; border: 1px #E0E0E0 solid; padding: 20px 25px;}
/*Phones (<768px)*/
@media (max-width: 767px) {
    .popup-scroll{padding: 10px 15px;}
}
